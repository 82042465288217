import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Suma from "../components/cart/suma"
import SEO from "../components/seo"
import CartContext from "../context/cart-context"

import Icon from "../images/icons/delete-photo.svg"

const CartPage = () => {
  let rowNum = 0

  return (
    <CartContext.Consumer>
      {cart => (
        <Layout>
          <SEO title="Cart" />

          <div className="cartMain">
            <table className="cartTable">
              <tbody>
                <tr>
                  <th>Nazwa</th>
                  <th>Miniaturka</th>
                  <th>Format</th>
                  <th>Cena</th>
                  <th></th>
                </tr>
                {cart.cartItems.map(item => {
                  let rowKey = "row" + rowNum
                  rowNum++
                  return (
                    <tr key={rowKey}>
                      <td>{item.name}</td>
                      <td>
                        <img
                          alt={item.name}
                          className="cartImg"
                          src={item.imgSrc}
                        />
                      </td>
                      <td>{item.format} cm</td>
                      <td>{item.cena}</td>
                      <td>
                        <Icon
                          onClick={cart.deleteFromCart.bind(this, item.name)}
                          className="cartDeleteIcon"
                        />
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td>SUMA:</td>
                  <Suma cart={cart.cartItems} />
                  <td className="cartButtonCell">
                    <Link to="/cart-finish">Zamów</Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Layout>
      )}
    </CartContext.Consumer>
  )
}

export default CartPage
