import React from "react"

const Suma = ({ cart }) => {
  let suma = 0
  cart.forEach(item => {
    suma += item.cena
  })
  return <td>{suma}</td>
}

export default Suma
